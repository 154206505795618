import Vue from 'vue'
import App from './App.vue'
// import App from './App_backup.vue'
import router from './router'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import {Message} from "element-ui"

// axios.defaults.baseURL = "https://rc.itooler.cn/api/v2/" // test
axios.defaults.baseURL = "https://rc.subin.cn/api/v2"
Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
