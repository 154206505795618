<template>
<div class="operation-item" @click.stop="meta.click">
  <div
      class="operation-item-icon"
      :style="{
    backgroundColor: meta.enable ? meta.background_active : meta.background,
    backgroundImage: `url('${meta.enable ? meta.active_icon : meta.icon}')`
  }"
  >

  </div>
  <div class="operation-item-info">{{meta.info}}</div>
</div>
</template>

<script>
export default {
  name: "OperationItem",
  props: {
    "background": {default: ""},
    "active_icon": {default: ""},
    "icon": {default: ""},
    "info": {default: ""},
    "active": {default: false},
    name: {
      default: ""
    },
    meta: {
      require: true
    },
    is_one: {
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.operation-item {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 33.33333%;
  max-width: 100%;
  min-width: 33.33333%;
  .operation-item-icon {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    border-radius: 30px;
    background-position: center center;
    background-size: 45% 45%;
  }
  .operation-item-info {
    margin-top: 5px;
    color: #FFFFFF;
    font-size: 12px;
  }
}
</style>
