<template>
  <div
      id="tuicallkit-id"
  >
    <div id="keep-time" v-show="calling">{{format_keep_time}}</div>
    <div class="tk-overlay" :class="{'small-view': me_main_video}" @click="change_video_view" v-show="calling && type === 1">
      <div class="tk-overlay_slot">
        <div class="user-box">
          <div class="avatar">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9Ii01IiB5PSItNSIgd2lkdGg9IjExMCIgaGVpZ2h0PSIxMTAiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yOTY0XzE3NTgpIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjQuOTk5OSA3MS41QzI0Ljk5OTkgNjEuMjM1NyAzMi45MzA0IDUyLjgyMzcgNDIuOTk4MyA1Mi4wNTdDNDMuNDkzOSA1Mi4wMTkzIDQzLjk5NDcgNTIgNDQuNDk5OSA1Mkg1Ny40OTk5QzU4LjAwNTEgNTIgNTguNTA1OSA1Mi4wMTkzIDU5LjAwMTQgNTIuMDU3QzY5LjA2OTQgNTIuODIzNyA3Ni45OTk5IDYxLjIzNTcgNzYuOTk5OSA3MS41Vjc4LjI4MjZIMjQuOTk5OUwyNC45OTk5IDcxLjVaIiBmaWxsPSIjRDVFMEYyIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDQuNDk5OSA0NS42MDQ4QzQ0LjQzMTIgNDUuNTcwNyA0NC4zNjI5IDQ1LjUzNiA0NC4yOTQ4IDQ1LjUwMDhDMzkuNTkwNiA0My4wNjk3IDM2LjM3NDkgMzguMTYwNCAzNi4zNzQ5IDMyLjVDMzYuMzc0OSAyNC40MjI4IDQyLjkyMjcgMTcuODc1IDUwLjk5OTkgMTcuODc1QzU5LjA3NyAxNy44NzUgNjUuNjI0OSAyNC40MjI4IDY1LjYyNDkgMzIuNUM2NS42MjQ5IDM4LjE2MDQgNjIuNDA5MiA0My4wNjk3IDU3LjcwNDkgNDUuNTAwOEM1Ny42MzY5IDQ1LjUzNiA1Ny41Njg2IDQ1LjU3MDcgNTcuNDk5OSA0NS42MDQ4QzU1LjU0MTggNDYuNTc3OSA1My4zMzQ3IDQ3LjEyNSA1MC45OTk5IDQ3LjEyNUM0OC42NjUgNDcuMTI1IDQ2LjQ1NzkgNDYuNTc3OSA0NC40OTk5IDQ1LjYwNDhaIiBmaWxsPSIjRDVFMEYyIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjk2NF8xNzU4IiB4MT0iNzMuNSIgeTE9Ii01IiB4Mj0iNSIgeTI9Ijk4LjUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzdDODU5QiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM3NDdDOTAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K" alt="">
          </div>
          <div class="nickname">{{nickName}}</div>
          <div class="tip" v-show="!calling">{{ is_dial ? "等待对方接受邀请" : `邀请你${type === 0 ? "语音" : "视频" }通话`}}</div>
        </div>
      </div>
      <div id="remote-user" class="local-stream-content"></div>
      <div class="tk-overlay_mask tk-blur" style="background-color: rgba(34, 38, 46, 0.85); backdrop-filter: blur(12px);"></div>
      <div class="tk-image">
        <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9Ii01IiB5PSItNSIgd2lkdGg9IjExMCIgaGVpZ2h0PSIxMTAiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yOTY0XzE3NTgpIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjQuOTk5OSA3MS41QzI0Ljk5OTkgNjEuMjM1NyAzMi45MzA0IDUyLjgyMzcgNDIuOTk4MyA1Mi4wNTdDNDMuNDkzOSA1Mi4wMTkzIDQzLjk5NDcgNTIgNDQuNDk5OSA1Mkg1Ny40OTk5QzU4LjAwNTEgNTIgNTguNTA1OSA1Mi4wMTkzIDU5LjAwMTQgNTIuMDU3QzY5LjA2OTQgNTIuODIzNyA3Ni45OTk5IDYxLjIzNTcgNzYuOTk5OSA3MS41Vjc4LjI4MjZIMjQuOTk5OUwyNC45OTk5IDcxLjVaIiBmaWxsPSIjRDVFMEYyIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDQuNDk5OSA0NS42MDQ4QzQ0LjQzMTIgNDUuNTcwNyA0NC4zNjI5IDQ1LjUzNiA0NC4yOTQ4IDQ1LjUwMDhDMzkuNTkwNiA0My4wNjk3IDM2LjM3NDkgMzguMTYwNCAzNi4zNzQ5IDMyLjVDMzYuMzc0OSAyNC40MjI4IDQyLjkyMjcgMTcuODc1IDUwLjk5OTkgMTcuODc1QzU5LjA3NyAxNy44NzUgNjUuNjI0OSAyNC40MjI4IDY1LjYyNDkgMzIuNUM2NS42MjQ5IDM4LjE2MDQgNjIuNDA5MiA0My4wNjk3IDU3LjcwNDkgNDUuNTAwOEM1Ny42MzY5IDQ1LjUzNiA1Ny41Njg2IDQ1LjU3MDcgNTcuNDk5OSA0NS42MDQ4QzU1LjU0MTggNDYuNTc3OSA1My4zMzQ3IDQ3LjEyNSA1MC45OTk5IDQ3LjEyNUM0OC42NjUgNDcuMTI1IDQ2LjQ1NzkgNDYuNTc3OSA0NC40OTk5IDQ1LjYwNDhaIiBmaWxsPSIjRDVFMEYyIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjk2NF8xNzU4IiB4MT0iNzMuNSIgeTE9Ii01IiB4Mj0iNSIgeTI9Ijk4LjUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzdDODU5QiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM3NDdDOTAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K"
            class="tk-image_inner" style="width: 100%; height: 100%; margin: 0; padding: 0; box-sizing: border-box; object-fit: cover;"
        >
      </div>
    </div>
    <div class="tk-overlay" :class="{'small-view': !me_main_video}" @click="change_video_view">
      <div class="tk-overlay_slot">
        <div class="user-box">
          <div class="avatar">
            <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9Ii01IiB5PSItNSIgd2lkdGg9IjExMCIgaGVpZ2h0PSIxMTAiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yOTY0XzE3NTgpIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjQuOTk5OSA3MS41QzI0Ljk5OTkgNjEuMjM1NyAzMi45MzA0IDUyLjgyMzcgNDIuOTk4MyA1Mi4wNTdDNDMuNDkzOSA1Mi4wMTkzIDQzLjk5NDcgNTIgNDQuNDk5OSA1Mkg1Ny40OTk5QzU4LjAwNTEgNTIgNTguNTA1OSA1Mi4wMTkzIDU5LjAwMTQgNTIuMDU3QzY5LjA2OTQgNTIuODIzNyA3Ni45OTk5IDYxLjIzNTcgNzYuOTk5OSA3MS41Vjc4LjI4MjZIMjQuOTk5OUwyNC45OTk5IDcxLjVaIiBmaWxsPSIjRDVFMEYyIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDQuNDk5OSA0NS42MDQ4QzQ0LjQzMTIgNDUuNTcwNyA0NC4zNjI5IDQ1LjUzNiA0NC4yOTQ4IDQ1LjUwMDhDMzkuNTkwNiA0My4wNjk3IDM2LjM3NDkgMzguMTYwNCAzNi4zNzQ5IDMyLjVDMzYuMzc0OSAyNC40MjI4IDQyLjkyMjcgMTcuODc1IDUwLjk5OTkgMTcuODc1QzU5LjA3NyAxNy44NzUgNjUuNjI0OSAyNC40MjI4IDY1LjYyNDkgMzIuNUM2NS42MjQ5IDM4LjE2MDQgNjIuNDA5MiA0My4wNjk3IDU3LjcwNDkgNDUuNTAwOEM1Ny42MzY5IDQ1LjUzNiA1Ny41Njg2IDQ1LjU3MDcgNTcuNDk5OSA0NS42MDQ4QzU1LjU0MTggNDYuNTc3OSA1My4zMzQ3IDQ3LjEyNSA1MC45OTk5IDQ3LjEyNUM0OC42NjUgNDcuMTI1IDQ2LjQ1NzkgNDYuNTc3OSA0NC40OTk5IDQ1LjYwNDhaIiBmaWxsPSIjRDVFMEYyIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjk2NF8xNzU4IiB4MT0iNzMuNSIgeTE9Ii01IiB4Mj0iNSIgeTI9Ijk4LjUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzdDODU5QiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM3NDdDOTAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K" alt="">
          </div>
          <div class="nickname">{{nickName}}</div>
          <div class="tip" v-show="!calling">{{ is_dial ? "等待对方接受邀请" : `邀请你${type === 0 ? "语音" : "视频" }通话`}}</div>
        </div>
      </div>
      <div id="local" class="local-stream-content"></div>
      <div class="tk-overlay_mask tk-blur" style="background-color: rgba(34, 38, 46, 0.85); backdrop-filter: blur(12px);"></div>
      <div class="tk-image">
        <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHg9Ii01IiB5PSItNSIgd2lkdGg9IjExMCIgaGVpZ2h0PSIxMTAiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yOTY0XzE3NTgpIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjQuOTk5OSA3MS41QzI0Ljk5OTkgNjEuMjM1NyAzMi45MzA0IDUyLjgyMzcgNDIuOTk4MyA1Mi4wNTdDNDMuNDkzOSA1Mi4wMTkzIDQzLjk5NDcgNTIgNDQuNDk5OSA1Mkg1Ny40OTk5QzU4LjAwNTEgNTIgNTguNTA1OSA1Mi4wMTkzIDU5LjAwMTQgNTIuMDU3QzY5LjA2OTQgNTIuODIzNyA3Ni45OTk5IDYxLjIzNTcgNzYuOTk5OSA3MS41Vjc4LjI4MjZIMjQuOTk5OUwyNC45OTk5IDcxLjVaIiBmaWxsPSIjRDVFMEYyIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDQuNDk5OSA0NS42MDQ4QzQ0LjQzMTIgNDUuNTcwNyA0NC4zNjI5IDQ1LjUzNiA0NC4yOTQ4IDQ1LjUwMDhDMzkuNTkwNiA0My4wNjk3IDM2LjM3NDkgMzguMTYwNCAzNi4zNzQ5IDMyLjVDMzYuMzc0OSAyNC40MjI4IDQyLjkyMjcgMTcuODc1IDUwLjk5OTkgMTcuODc1QzU5LjA3NyAxNy44NzUgNjUuNjI0OSAyNC40MjI4IDY1LjYyNDkgMzIuNUM2NS42MjQ5IDM4LjE2MDQgNjIuNDA5MiA0My4wNjk3IDU3LjcwNDkgNDUuNTAwOEM1Ny42MzY5IDQ1LjUzNiA1Ny41Njg2IDQ1LjU3MDcgNTcuNDk5OSA0NS42MDQ4QzU1LjU0MTggNDYuNTc3OSA1My4zMzQ3IDQ3LjEyNSA1MC45OTk5IDQ3LjEyNUM0OC42NjUgNDcuMTI1IDQ2LjQ1NzkgNDYuNTc3OSA0NC40OTk5IDQ1LjYwNDhaIiBmaWxsPSIjRDVFMEYyIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjk2NF8xNzU4IiB4MT0iNzMuNSIgeTE9Ii01IiB4Mj0iNSIgeTI9Ijk4LjUiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzdDODU5QiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM3NDdDOTAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K"
            class="tk-image_inner" style="width: 100%; height: 100%; margin: 0; padding: 0; box-sizing: border-box; object-fit: cover;"
        >
      </div>
    </div>
    <div class="operation">

    </div>
    <div class="operate-box" :class="{'line-one': is_dial && type === 0, 'in-video-call': calling && type === 1}">
      <!--      通话中是显示麦克风-->
      <operationItem name="microphone" :meta="operate.microphone" v-show="calling"></operationItem>

      <!--      视频通话中显示扬声器-->
      <operationItem name="loudspeaker" :meta="operate.loudspeaker" v-show="calling && type === 1"></operationItem>

      <!--      视频通话中显示摄像头-->
      <operationItem name="camera" :meta="operate.camera" v-show="calling && type === 1"></operationItem>

      <!--      视频通话拨号中显示旋转-->
      <operationItem name="rotation" :meta="operate.rotation" v-show="!calling && type === 1 && is_dial"></operationItem>

      <!--      挂断-->
      <operationItem name="hangup" :meta="operate.hangup" class="hangup"></operationItem>

      <!--      视频通话号中显示旋转-->
      <operationItem name="rotation" :meta="operate.rotation" v-show="calling && type === 1"></operationItem>

      <!--      语音通话中显示扬声器-->
      <operationItem name="loudspeaker" :meta="operate.loudspeaker" v-show="calling && type === 0"></operationItem>

      <!--      视频通话拨号中显示摄像头-->
      <operationItem name="camera" :meta="operate.camera" v-show="!calling && type === 1 && is_dial"></operationItem>

      <!--      接听-->
      <operationItem name="answer" :meta="operate.answer" v-show="!is_dial && !calling"></operationItem>
    </div>
  </div>
</template>

<script>
import operationItem from "./common/OperationItem";
import TRTC from "trtc-sdk-v5";
export default {
  name: "TUICallKit",
  components: {operationItem},
  props: {
    type: { require: true}, // 0:语音 1:视频
    max_wait_time: { default: 1000 * 60}, // 0:语音 1:视频
    is_dial: {default: false}, // 是否拨号
    afterCalling: {default: null},
    beforeCalling: {default: null},
    nickName: {default: "", type: String},
  },
  data() {return {
    me_main_video: true,
    keep_time: 0,
    timekeeper: null,
    calling: false,
    cameraId: null,
    operate: {
      camera: {
        active_icon: require("./common/icon/active_camera.png"),
        icon: require("./common/icon/close_camera.png"),
        background: "rgba(255, 255, 255, .1)",
        background_active: "#ffffff",
        enable: false,
        click: this.handleCamera,
        info: "摄像头已关"
      },
      hangup: {
        icon: require("./common/icon/hangup.png"),
        background: "red",
        background_active: "red",
        info: "挂断",
        click: this.exitRoom
      },
      answer: {
        icon: require("./common/icon/answer.png"),
        background: "#51C271",
        background_active: "#51C271",
        info: "接听",
        click: this.handleAnswer
      },
      rotation: {
        active_icon: require("./common/icon/rotation_camera.png"),
        icon: require("./common/icon/rotation_camera.png"),
        background: "rgba(255, 255, 255, .1)",
        background_active: "rgba(255, 255, 255, .1)",
        useFrontCamera: false,
        click: this.handleRotation,
        info: "旋转"
      },
      microphone: {
        active_icon: require("./common/icon/microphone_active.png"),
        icon: require("./common/icon/microphone.png"),
        background: "rgba(255, 255, 255, .1)",
        background_active: "#ffffff",
        enable: false,
        click: this.handleMicrophone,
        info: "麦克风已关"
      },
      loudspeaker: {
        active_icon: require("./common/icon/loudspeaker_active.png"),
        icon: require("./common/icon/loudspeaker.png"),
        background: "rgba(255, 255, 255, .1)",
        background_active: "#ffffff",
        enable: false,
        click: this.handleLoudspeaker,
        info: "扬声器已关"
      }
    },
    deviceList: {
      camera: [],
      microphone: [],
    },
    camStatus: 'stopped', // stopped, starting, started, stopping
    micStatus: 'stopped',
    roomStatus: 'exited', // exited, exiting, entering, entered
  }},
  computed: {
    format_keep_time(){
      var h = Math.floor(this.keep_time / 3600);
      var m = Math.floor(this.keep_time % 3600 / 60);
      var s = Math.floor(this.keep_time % 3600 % 60);
      var hDisplay = h > 9 ? h : "0" + h;
      var mDisplay = m > 9 ? m : "0" + m;
      var sDisplay = s > 9 ? s : '0' + s;
      return hDisplay + ":" + mDisplay + ":" + sDisplay;
    },
  },
  watch: {
    'operate.camera.enable': async function (val) {
      this.operate.camera.info = val ? await this.handleStartLocalVideo() : await this.handleStopLocalVideo()
    },
    'operate.microphone.enable': async function (val) {
      this.operate.microphone.info = val ? await this.handleStartLocalAudio() : await this.handleStopLocalAudio()
    },
    "operate.loudspeaker.enable": async function (val) {
      this.operate.loudspeaker.info = val ? await this.handleStartLocalLoudspeaker() : await this.handleStopLocalLoudspeaker()
    },
    "operate.rotation.useFrontCamera": function (val) {
      // if(this.deviceList.camera[Number(val)]){this.trtc.updateLocalVideo({ option: { cameraId: this.deviceList.camera[Number(val)].deviceId }});}
      this.trtc.updateLocalVideo({ option: { useFrontCamera: val }});
    },
  },
  methods: {
    close_timekeeper() {if(this.timekeeper) clearInterval(this.timekeeper)},
    handleAnswer() {this.$emit("answer", this.type)},
    change_video_view() {console.log("change_video_view"); this.me_main_video = !this.me_main_video},
    async getDeviceList() {
      this.deviceList.camera = await TRTC.getCameraList();
      this.deviceList.microphone = await TRTC.getMicrophoneList();
    },
    handleCamera() {this.handleDevice("camera")},
    handleMicrophone() {this.handleDevice("microphone")},
    handleLoudspeaker() {this.handleDevice("loudspeaker")},
    handleRotation() {if(this.camStatus === "started")this.operate.rotation.useFrontCamera = !this.operate.rotation.useFrontCamera},
    handleDevice(name){
      this.operate[name].enable = !this.operate[name].enable
      // this.operate[name].background = this.operate[name].enable ? "#FFFFFF" : "rgba(255, 255, 255, .1)"
    },
    installEventHandlers() {
      // 远端用户进房
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      // 远端用户退房
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);

      // 当远端用户开启摄像头
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      // // 当远端用户关闭摄像头
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
    },
    initTRTC() {
      if (this.trtc) return;
      this.trtc = TRTC.create();
    },
    async enterRoom(config) {
      console.log("config", config)
      Object.keys(config).forEach(key => {this[key] = config[key]})
      this.roomStatus = 'entering';
      this.initTRTC();
      try {
        await this.trtc.enterRoom({roomId: parseInt(config.roomId), sdkAppId: parseInt(this.sdkAppId, 10), userId: config.userId, userSig: config.userSig,});
        this.roomStatus = 'entered';
        this.installEventHandlers();
        this.handleLoudspeaker()
        this.handleMicrophone()
        if(this.beforeCalling !== null)this.beforeCalling()
        setTimeout(() => { if (!this.calling) this.handleRemoteUserExit()}, this.max_wait_time)
        setTimeout(() => { if (!this.calling && !this.is_dial) this.handleRemoteUserExit()}, 1000)
      } catch (error) {
        this.roomStatus = 'exited';
        console.error('enterRoom room failed', error);
        throw error;
      }
    },
    async exitRoom() {
      this.close_timekeeper()
      this.me_main_video = true
      this.keep_time = 0
      this.timekeeper = null
      this.operate.camera.enable = false
      this.operate.rotation.useFrontCamera = false
      this.operate.microphone.enable = false
      this.operate.loudspeaker.enable = false
      if (this.roomStatus !== 'entered') {return;}
      this.roomStatus = 'exiting';
      setTimeout(async () => {
        try {
          this.calling = false
          await this.trtc.exitRoom();
          // this.trtc.setRemoteAudioVolume()
          this.roomStatus = 'exited';
          if (this.micStatus === 'started') await this.handleStopLocalAudio();
          if (this.camStatus === 'started') await this.handleStopLocalVideo();
          if(this.afterCalling!== null)this.afterCalling()
          console.log("Remote User entered")
        } catch (error) {
          // this.roomStatus = 'entered';
          // throw error;
        }
      }, 500)



    },
    async handleStartLocalAudio() {
      this.micStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalAudio({option: {microphoneId: this.microphoneId,},});
        this.micStatus = 'started';
        return "麦克风已开"
      } catch (error) {
        this.micStatus = 'stopped';
        throw error;
      }
    },
    async handleStopLocalAudio() {
      if (this.micStatus !== 'started') {return;}
      this.micStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalAudio();
        this.micStatus = 'stopped';
        return "麦克风已关"
      } catch (error) {
        this.micStatus = 'started';
        throw error;
      }
    },
    async handleStartLocalVideo() {
      console.log("this.deviceList.camera[0]", this.deviceList.camera)
      this.camStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalVideo({
          view: 'local',
          option: {
            // cameraId: this.deviceList.camera[0].deviceId,
            useFrontCamera: false,
            profile: '1080p',
          },
        });
        this.camStatus = 'started';
        return "摄像头已开"
      } catch (error) {
        this.camStatus = 'stopped';
        throw error;
      }
    },
    async handleStopLocalVideo() {
      if (this.camStatus !== 'started') {return;}
      this.camStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalVideo();
        this.camStatus = 'stopped';
        return "摄像头已关"
      } catch (error) {
        this.camStatus = 'started';
        throw error;
      }
    },

    async handleStartLocalLoudspeaker() {
      this.louStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.muteRemoteAudio("*", false);
        this.louStatus = 'started';
        return "扬声器已开"
      } catch (error) {this.louStatus = 'stopped';throw error;}
    },
    async handleStopLocalLoudspeaker() {
      if (this.louStatus !== 'started') {return;}
      this.louStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.muteRemoteAudio("*", true);
        this.louStatus = 'stopped';
        return "扬声器已关"
      } catch (error) {this.louStatus = 'started';throw error;}
    },

    handleRemoteUserEnter(event) {
      const { userId } = event;
      console.log(`Remote User [${userId}] entered`);
      this.calling = true
      this.timekeeper = setInterval(() => {this.keep_time++; console.log(this.keep_time)}, 1000)
    },
    handleRemoteUserExit() {this.exitRoom(); this.close_timekeeper()},

    handleRemoteVideoAvailable(event) {
      const { userId, streamType } = event;
      try {
        console.log(`[${userId}] [${streamType}] video available`);
        this.trtc.startRemoteVideo({ userId, streamType, view: "remote-user" });
        console.log(`Play remote video success: [${userId}]`);
      } catch (error) {
        this.addFailedLog(`Play remote video failed: [${userId}], error: ${error.message}`);
      }
    },
    handleRemoteVideoUnavailable(event) {
      console.log(`[${event.userId}] [${event.streamType}] video unavailable`);
      const { streamType } = event;
      this.trtc.stopRemoteVideo({ userId: event.userId, streamType });
    },

  },
  async mounted() {
    this.trtc = TRTC.create();
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(async (stream) => {
      stream.getTracks().forEach(track => track.stop());
      await this.getDeviceList()
    });
  }
}
</script>

<style scoped lang="scss">

#tuicallkit-id {
  width: 100%;
  height: 100%;
  position: relative;
  #keep-time {
    top: 20px;
    width: 100%;
    color: #ffffff;
    position: absolute;
    text-align: center;
    z-index: 0;
  }
  .tk-overlay.small-view {
    z-index: 3;
    //width: 20%;
    //height: 20%;
    transform-origin: calc(100% - 10px) 20px;
    right: 10px;
    top: 20px;
    transform: scale(0.25);
    .nickname {display: none !important}
  }
  .tk-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -3;
    .local-stream-content{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
    }
    .tk-overlay_slot{
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      .user-box {
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 26%;
        width: 100%;
        .avatar {
          border-radius: 5px;
          overflow: hidden;
          margin-bottom: 10px;
          width: 100px;
          height: 100px;
          img {float: left; width: 100%; height: 100%}
        }
        .nickname {font-size: 20px}
        .tip{margin-top: 10px;padding: 10px;}
      }
    }
    .tk-overlay_mask{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #00000080;
      z-index: 1;
    }
    .tk-image {
      width: 100%;
      height: 100%;
    }
  }
  .local-stream-content{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    border: 1px solid #FFF;
  }
  .tk-overlay_slot{
    width: 100%;
    height: 100%;
    position: absolute;
    .user-box {
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      position: absolute;
      justify-content: center;
      align-items: center;
      top: 26%;
      width: 100%;
      .avatar {
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
        width: 100px;
        height: 100px;
        img {float: left; width: 100%; height: 100%}
      }
      .nickname {font-size: 20px}
      .tip{margin-top: 10px;padding: 10px;}
    }
  }
  .line-one {
    justify-content: center !important;
  }
  .in-video-call {
    ::v-deep .hangup {
      margin-left: 33.33333%;
    }
  }
  .operate-box {
    display: flex;
    position: absolute;
    bottom: 0;
    //height: 27%;
    width: 100%;
    padding: 40px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
