<template>
  <div id="app">
    <div id="user-id"><h4>{{username}}</h4></div>
    <div id="prompt">请在下面选择呼叫方式</div>
    <div id="call-option">
      <div class="option" @click="get_call_user(false)">
        <div class="option-icon"></div>
        <div class="title">语音</div>
      </div>
      <div class="option" @click="get_call_user(true)">
        <div class="option-icon"></div>
        <div class="title">视频</div>
      </div>
    </div>
    <div id="TUICallKit" :style="{display: is_call ? 'block' : 'none'}">
      <TUICallKit
          :beforeCalling="beforeCalling"
          :afterCalling="afterCalling"
          :type="type"
          :is_dial="is_dial"
          :nickName="username"
          ref="callKit"
          @answer="get_call_user"
      />
    </div>
  </div>
</template>
<script>
import { Loading } from 'element-ui';
import { TUICallKitServer, TUICallType } from "@tencentcloud/call-uikit-vue2.6";
// import * as GenerateTestUserSig from "./GenerateTestUserSig-es";
// import TRTC from 'trtc-sdk-v5';
import LibGenerateTestUserSig from '../utils/lib-generate-test-usersig.min.js';
// import TUICallKit from "./components/TUICallKit"
import TUICallKit from "./components/TUICallKit/TUICallKit"
import {v4 as uuidv4} from "uuid";
import * as GenerateTestUserSig from "@/GenerateTestUserSig-es";

export default {
  name: 'App',
  data() {
    return {
      type: 1,
      is_dial: true,

      parkId: null,
      deviceId: null,
      loading: null,
      is_call: false,
      // SDKAppID、userSig 的获取参考下面步骤
      username: "",
      userID: '',     // 主叫的 userID
      callUserID: '', // 被叫的 userID
      SDKAppID: 1400504157,    // Replace with your SDKAppID
      SecretKey: '6fb1fd322708cb8db5480eeba222ceb3ccabc4dfa2d108a2c264f5393850403f',  // Replace with your SecretKey
      userSig: "",
      uid: ""
    };
  },
  components: {
    TUICallKit
  },
  methods: {
    zfill: (num, width) => { return new Array(Math.max(width | 0, ("" + num).length) - ("" + num).length + 1).join('0') + parseInt(num)},
    beforeCalling() {this.is_call = true; if(this.loading) this.loading.close()},
    afterCalling() {this.is_call = false},
    async call(type) {
      this.type = type ? 1 : 0
      await this.$refs.callKit.enterRoom({roomId: this.userID, sdkAppId: this.SDKAppID, userId: this.uid, userSig: this.userSig})
    },
    get_park_info() {return this.$axios.post(`/gateway/${this.parkId}`, {"userid": this.uid, "method": "createUserSig", "deviceid": this.deviceId, "source": "app"})},
    get_call_user(localvideo) {
      return this.$axios.post(`/gateway/${this.parkId}`, {
        'userid': this.uid, 'method': 'callRemote', 'roomid': this.userID,
        'devicetype': this.deviceType, 'deviceid': this.deviceId, 'parkid': this.parkId,
        'userinfo': {
          'nickName': this.username, 'gender': 0, 'language': 'zh_CN',
          'city': '', 'province': '', 'country': '', 'avatarUrl': ''
        },
        'localvideo': !!localvideo, 'source': 'app'
      }).then((res) => {
        this.loading = Loading.service({background: "rgba(0, 0, 0, .8)", fullscreen: true});
        if(res.data.code !== 0) {this.$message.error('系统异常03'); this.loading.close(); return}
        if(!res.data.data || !res.data.data.staff) {this.$message.error('系统异常04'); this.loading.close(); return}
        // this.callUserID = res.data.data.staff
        // this.userSig = GenerateTestUserSig.genTestUserSig({
        //   userID:  this.uid,
        //   SDKAppID: Number(this.SDKAppID),
        //   SecretKey: this.SecretKey,
        // }).userSig;
        this.call(localvideo)
      }).catch((error) => {this.$message.error('系统异常05');})
    },
    get_uid() {
      this.uid= this.$route.query.uid || localStorage.getItem("uid") || uuidv4()
      localStorage.setItem("uid", this.uid)
    }
  },
  created() {
    this.get_uid()
    // this.loading = Loading.service({background: "rgba(0, 0, 0, .8)", fullscreen: true});
    this.parkId = this.$route.query.parkId
    this.deviceId = this.$route.query.deviceId

    if (this.$route.query.uid) {
      this.is_call = true
      this.is_dial = false
    }

    if(this.parkId && this.deviceId) {
      this.get_park_info().then((res) => {
        if(res.data.code !== 0) {this.$message.error('系统异常01'); return}
        // this.userID = `${parseInt(this.parkId)}${this.zfill(parseInt(this.deviceId), 5)}`
        // this.username = `${res.data.data.parkname} 通道${this.deviceId}`
        this.userID = `1${this.parkId}${this.deviceId}`
        this.username = `${res.data.data.parkname} ${res.data.data.laneinfo.lanename}`
        this.SDKAppID = res.data.data.sig.sdkAppId
        this.userSig = res.data.data.sig.userSig
        // this.init()
      }).catch((error) => {this.$message.error('系统异常02');})
    }
  }
}
</script>
<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
  background-color: #333333;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.el-loading-spinner .path {
  -webkit-animation: loading-dash 1.5s ease-in-out infinite;
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 4;
  stroke-linecap: round;
  stroke:#FFF!important;
}
#app{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  >#TUICallKit {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
  }
  >#user-id{
    display: flex;
    color: #fff;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  >#prompt {
    padding-bottom: 120px;
    color: #8F9D99;
    width: 100%;
    text-align: center
  }
  >#call-option{
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    >div.option:nth-child(2){>.option-icon{background-color: #17CBF0; background-image: url("./assets/images/icon/video.png") !important}}
    >div.option {
      padding: 10px 30px;
      width: 80px;
      box-sizing: content-box;
      >.title{color: #FFF; text-align: center}
      >.option-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        border-radius: 40px;
        background-color: #33CD17;
        margin-bottom: 20px;
        background-image: url("./assets/images/icon/voice.png");
        background-position: center center;
        background-size: 50% 50%;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
